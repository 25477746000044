<template>
  <div>
    <div class="banner-img-font">
      <p>{{ title }}</p>
      <p v-html="memo"></p>
    </div>
  </div>

</template>

<script>
export default {
  name: "Banner",
  props: {
    title: {
      default: '告诉我们您需要做什么',
    },
    memo: {
      default: '在几分钟内推荐匹配度高的人才，查看个人资料、发布新的技能、评价并与他们聊天。</br>仅当您对他们的工作100%满意时才向他们付款。'
    }
  }
}
</script>

<style lang="less" scoped>
.banner-img-font {
  background-image: url(~@/assets/banner/task-banner.jpg);
  width: 100%;
  height: 400px;
  background-size: cover;
}
.banner-img-font p:nth-child(1){
  font-size: 18px;
  position: relative;
  width: 800px;
  margin: 0 auto;
  top: 40px;
  color: #fff;
}
.banner-img-font p:nth-child(2){
  font-size: 14px;
  position: relative;
  width: 800px;
  margin: 0 auto;
  top: 60px;
  color: #fff;
  line-height: 24px;
}
</style>
