<template>
  <div class="banner">
    <!-- 审核成功 -->
    <div class="attestationRes-succeed" v-show="true">
      <!-- 审核成功中间 -->
      <div class="attestationRes-succeed-center">
        <div class="attestationRes-succeed-center-img">
          <img src="@/icons/svg/audit/成功120X120.svg" alt="" />
          <h2>审核成功</h2>
          <p>恭喜您，您的服务已经成功发布,雇主方看到您的服务后,就可能和您取得联系!</p>
          <button>返回我的主页</button>
        </div>
      </div>
    </div>
    <!-- 审核中 -->
    <div class="attestationRes-inReview" v-show="false">
      <!-- 审核中中间 -->
      <div class="attestationRes-succeed-center">
        <div class="attestationRes-succeed-center-img">
          <img src="@/icons/svg/audit/审核中120X120.svg" alt="" />
          <h2>审核中</h2>
          <b>平台将在3个工作日内审核您的发布 </b><br>
          <p>审核期内,您可以<strong>修改</strong>您的发布需求,若有变动,也可以将该任务直接<span>关闭</span></p>
          <button>查看详情</button>
        </div>
      </div>
    </div>
    <!-- 审核失败 -->
    <div class="attestationRes-fuiled" v-show="false">
      <div class="attestationRes-fuiled-center">
        <div class="attestationRes-fuiled-center-img">
          <img src="@/icons/svg/audit/失败120X120.svg" alt="">
        <h2>审核失败</h2>
        <p>我们检测到您发布的文字内容有洗及到政治敏感言论,请谨慎文明措辞!</p>
        <button>返回修改</button>
        </div>

      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Banner from "@/components/Banner"
export default {
  components:{
    Banner
  },

}
</script>

<style lang="less" scoped>
.banner{
    background-color: #F4F4F4;
}
.banner-width {
  width: 100%;
  margin-top: -20px;
  .banner-img-font {
    background-image: url(~@/assets/banner/底图.jpg);
    width: 100%;
    height: 400px;
    background-size: cover;
    p {
      position: relative;
      width: 800px;
      margin: 0 auto;
      top: 60px;
      color: #fff;
      strong {
        font-size: 24px;
        display: block;
        margin-bottom: 23px;
      }
      li {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}
/* 审核成功 */
.attestationRes-succeed{
  width: 800px;
  height: 461px;
  background: #FFFFFF;
  margin: 0 auto;
  margin-top: -175px;
  .attestationRes-succeed-center{
    width: 495px;
    height: 261px;
    margin: 0 auto;
    float: left;
    margin-left: 153px;
    margin-top: 100px;
    text-align: center;
    .attestationRes-succeed-center-img{
      margin: 0 auto;
      img{
      width: 120px;
      height: 120px;
    }
    h2{
      margin-top: 19px;
      font-size: 18px;
      color: #474747;
    }
    p{
      margin-top: 13px;
      margin-bottom: 40px;
      font-size: 14px;
    }
    }
  }
}
/* 审核中 */
.attestationRes-inReview{
    width: 800px;
    height: 485px;
    background: #FFFFFF;
    margin: 0 auto;
    margin-top: -175px;
    .attestationRes-succeed-center{
    width: 460px;
    height: 285px;
    margin: 0 auto;
    float: left;
    margin-left: 175px;
    margin-top: 100px;
    text-align: center;
    .attestationRes-succeed-center-img{
      margin: 0 auto;
      img{
      width: 120px;
      height: 120px;
    }
    h2{
      font-size: 18px;
      color: #474747;
      margin-top: 20px;
      margin-bottom: 13px;
    }
    b,p{
      font-size: 14px;
      color: #575757;
    }
    p{
      margin-top: 10px;
      margin-bottom: 40px;
    }
    }
  }
}
/* 审核失败 */
.attestationRes-fuiled{
  width: 800px;
  height: 461px;
  background: #FFFFFF;
  margin: 0 auto;
  margin-top: -175px;
  .attestationRes-fuiled-center{
    width: 439px;
    height: 261px;
    border: 1px solid red;
    margin-top: 100px;
    text-align: center;
    margin-left: 180px;
    float: left;
    .attestationRes-fuiled-center-img{
      margin: 0 auto;
      img{
      width: 120px;
      height: 120px;
    }
    h2{
      font-size: 18px;
      color: #474747;
      margin-top: 18px;
    }
    p{
      font-size: 14px;
      color: #e4007f;
      margin-top: 13px;
      margin-bottom: 40px;
    }
    }

  }
}
button{
  width: 220px;
  height: 36px;
  background: #00A2E6;
  font-size: 18px;
  color: #fff;
}
</style>
